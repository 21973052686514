<template>
  <div>
    <div class="card border-primary mx-auto" style="max-width: 100%">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-4">
            <div class="card">
              <activity-count
                :loading="loadingInfo"
                :count="info.activity_count"
              >
              </activity-count>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="card">
              <student-count
                :loading="loadingInfo"
                :count="info.student_count"
                :non_inscrit_count="info.student_count_non_inscrit"
              ></student-count>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="card">
              <employeeCount
                :loading="loadingInfo"
                :count="info.classRoom_count"
              ></employeeCount>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4">
            <div class="card">
              <div class="card-header">{{ $t("accueil.serviceActiv") }}</div>
              <div class="card-body" style="height: 362px; overflow: auto">
                <a-timeline>
                  <a-timeline-item
                    v-for="service in info['service/activity']"
                    :key="service._id"
                    >{{ service.name }}: {{ service.activity_count }}
                    {{ $t("accueil.activite")
                    }}{{
                      service.activity_count > 1 ? "s" : ""
                    }}</a-timeline-item
                  >
                </a-timeline>
              </div>
            </div>
          </div>
          <div class="col-xl-8">
            <div class="row">
              <div class="col-lg-6">
                <div class="card">
                  <div class="card-body">
                    <totalProfit
                      :money="statistique"
                      :loading="loadingStatstiques"
                      color="#007bff"
                      type="in"
                    ></totalProfit>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <totalProfit
                      :money="statistique"
                      :loading="loadingStatstiques"
                      color="#46be8a"
                      type="out"
                    ></totalProfit>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="card" style="height: 428px; overflow: auto">
                  <div class="card-header m-0 p-2 pt-3 pl-3 pr-3">
                    <a-button @click="link" style="float: right">{{
                      $t("punition.details")
                    }}</a-button>
                    <h6>
                      <b>{{ $t("recette.checksDueThisWeek") }}</b>
                    </h6>
                  </div>
                  <div class="card-body">
                    <a-col v-if="loadingCheques" :span="12" :offset="11">
                      <a-spin style="margin-top: 100px" size="large" />
                    </a-col>
                    <div v-else>
                      <div v-for="cheque in cheques" :key="cheque._id">
                        <p class="m-0">
                          <b>{{ $t("recette.numero") }} : </b> {{ cheque.num }}
                        </p>
                        <p class="m-0">
                          <b>{{ $t("recette.montant") }} : </b>
                          {{ cheque.montant }}
                        </p>
                        <p class="m-0">
                          <b>{{ $t("recette.dateEcheance") }} : </b>
                          {{ moment(cheque.dateEchance).format("DD/MM/YYYY") }}
                          --
                          {{ moment(cheque.dateEchance).fromNow() }}
                        </p>
                        <a-divider></a-divider>
                      </div>
                      <a-empty v-if="cheques.length == 0" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-header">
                <div class="cui__utils__heading mb-0">
                  <strong>{{ $t("accueil.RevenuDep") }}</strong>
                </div>
                <span class="mr-2">
                  <span
                    class="kit__utils__donut kit__utils__donut--primary"
                  ></span>
                  {{ $t("accueil.depenses") }}
                </span>
                <span class="mr-2">
                  <span
                    class="kit__utils__donut kit__utils__donut--success"
                  ></span>
                  {{ $t("accueil.revenu") }}
                </span>
              </div>
              <div class="card-body">
                <div
                  class="h-100 d-flex flex-column justify-content-center align-items-center row"
                  :class="$style.chartPieExample"
                >
                  <div class="mb-4 col-md-12">
                    <vue-chartist
                      class="height-300 mt-4"
                      style="width: 100%"
                      :type="'Bar'"
                      :data="gainsValues"
                      :options="monthChartistOptions"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CuiChart4 from "./components/4";
import activityCount from "./components/activityCount";
import studentCount from "./components/studentCount";
import employeeCount from "./components/employeeCount";
import totalProfit from "./components/totalProfit";
import CuiChart12v1 from "./components/12v1";
import VueChartist from "v-chartist";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import apiClient from "@/services/axios";
import _ from "lodash";
import moment from "moment";

const supportCasesPieData = {
  series: [
    {
      name: "Impayé",
      value: 65,
    },
    {
      name: "Payé",
      value: 120,
    },
  ],
};

const supportCasesPieOptions = {
  donut: true,
  donutWidth: 35,
  showLabel: false,
  fullWidth: true,
  plugins: [
    ChartistTooltip({
      anchorToPoint: false,
      appendToBody: true,
      seriesName: false,
    }),
  ],
};

const monthChartistOptions = {
  seriesBarDistance: 20,
  plugins: [
    ChartistTooltip({
      currency: "TND ",
      seriesName: true,
    }),
  ],
  legend: {
    display: true,
  },
};

const tableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Position",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: "Office",
    dataIndex: "office",
    key: "office",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Salary",
    dataIndex: "salary",
    key: "salary",
    sorter: (a, b) => a.salary - b.salary,
  },
];

export default {
  created() {
    this.loadingCheques = true;
    this.loadingInfo = true;
    this.loadingStatstiques = true;

    apiClient
      .get("/home")
      .then((res) => (this.info = res.data))
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        this.loadingInfo = false;
      });

    apiClient
      .get("/money/statistique")
      .then((res) => {
        this.statistique = {
          ...res.data,
          beneficeTotal: Number(res.data.beneficeTotal),
          depenseTotal: Number(res.data.depenseTotal),
        };
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        this.loadingStatstiques = false;
      });

    apiClient
      .post("/cheque/filter", {
        query: { this_week: true },
      })
      .then((res) => {
        this.cheques = res.data;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        this.loadingCheques = false;
      });
  },
  components: {
    CuiChart4,
    activityCount,
    studentCount,
    employeeCount,
    totalProfit,
    CuiChart12v1,
    "vue-chartist": VueChartist,
  },
  computed: {
    gainsValues() {
      const data = [[], []];
      for (let i = 0; i < 12; i++) {
        data[0].push(
          Number(this.statistique.benificeRecettePerMonth[i].benefice)
        );
        data[1].push(
          Number(this.statistique.benificeRecettePerMonth[i].depense)
        );
      }

      return {
        labels: moment.months().map((a) => a[0].toUpperCase() + a.slice(1)),
        series: data,
      };
    },
  },
  data() {
    return {
      loadingInfo: false,
      loadingCheques: false,
      loadingStatstiques: false,
      FISCAL: parseFloat(process.env.VUE_APP_FISCAL),
      TVA: parseFloat(process.env.VUE_APP_TVA),
      info: {},
      money: { in: 0, out: 0 },
      supportCasesPieData,
      supportCasesPieOptions,
      monthChartistOptions,
      tableColumns,
      cheques: [],
      totalMoney: { gains: [], loses: [] },
      statistique: {
        beneficeTotal: 0,
        depenseTotal: 0,
        benificeRecettePerMonth: [
          { benefice: 0, depense: 0 },
          { benefice: 0, depense: 0 },
          { benefice: 0, depense: 0 },
          { benefice: 0, depense: 0 },
          { benefice: 0, depense: 0 },
          { benefice: 0, depense: 0 },
          { benefice: 0, depense: 0 },
          { benefice: 0, depense: 0 },
          { benefice: 0, depense: 0 },
          { benefice: 0, depense: 0 },
          { benefice: 0, depense: 0 },
          { benefice: 0, depense: 0 },
        ],
      },
    };
  },
  methods: {
    link() {
      this.$router.push({
        path: "/cheques",
      });
    },
    moment,
  },
};
</script>
<style lang="scss" module>
@import "@/components/mixins.scss";
</style>
<style lang="scss" scoped>
@import "@/components/mixins.scss";
.chartPieExample {
  :global(.v-chartist-container) {
    height: rem(200);
  }

  :global(.ct-series-a) path {
    stroke: $success;
  }

  :global(.ct-series-b) path {
    stroke: $primary;
  }

  :global(.ct-series-c) path {
    stroke: $danger;
  }

  :global(.ct-series) path {
    transition: stroke-opacity 0.2s ease-in-out;
    &:hover {
      stroke-opacity: 0.8;
    }
  }
}
</style>
